<template>
  <b-container>
    <ResourceList :can-delete="hasAnyPermission(['delete-suppliers'])"
                  :can-select-many="hasAnyPermission(['delete-suppliers'])" :data="data"
                  :has-buttons="false" :is-deleting="isDeleting"
                  :is-loading="isLoading" :pagination="pagination" :search-query="$route.query.query"
                  create-to="dash.suppliers.create" resource="supplier"
                  title="Suppliers" @paginate="onPaginate" @search="onSearch" @delete-many="onDeleteMany">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary">
              <router-link :to="{ name: 'dash.suppliers.view', params: { id: item.id } }" class="text-dark">#{{ item.id
                }} {{ item.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-0 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>
          <div class="d-flex flex-row flex-fill">
            <small class="mb-0 text-muted">{{ item.email }}</small>
          </div>
          <div class="d-flex flex-row flex-fill mt-2">
            <b-button v-b-popover.hover.bottom="'View more information about this supplier.'"
                      :to="{ name: 'dash.suppliers.view', params: { id: item.id } }" class="mr-1 mb-0"
                      size="sm"
                      variant="primary">
              <b-icon icon="eye"/>
              View
            </b-button>
            <b-button v-b-popover.hover.bottom="'Edit this supplier.'"
                      :to="{ name: 'dash.suppliers.update', params: { id: item.id } }" class="mr-1 mb-0" size="sm"
                      variant="secondary">
              <b-icon icon="pen"/>
              Edit
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [currentUser],
  created() {
    this.fetchMany(this.$route.query)
  },
  components: {
    ResourceList,
    FriendlyDate,
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("suppliers", ["data", "isDeleting", "isLoading", "pagination"]),
  },
  methods: {
    ...mapActions("suppliers", ["deleteMany", "fetchMany"]),
    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids})
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },

  },
}
</script>
<style>
</style>
