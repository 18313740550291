<template>
	<b-container>
		<b-row class="mb-3">
			<b-col cols="6">
				<h1 class="font-weight-bold text-primary">View Supplier</h1>
			</b-col>
			<b-col cols="6">
				<div class="d-none d-md-flex flex-row justify-content-end pt-2">
					<b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'Edit this supplier.'" :to="{ name: 'dash.suppliers.update', params: { id: supplier.id } }"><b-icon icon="pen" /> Edit Supplier</b-button>
				</div>
			</b-col>
		</b-row>
		<b-row v-if="isLoading">
			<b-col cols="12">
				<p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
			</b-col>
		</b-row>
		<b-row v-if="!isLoading">
			<b-col cols="12">
				<b-row>
					<b-col cols="12">
						<b-form-group label="Company Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
							<b-form-input disabled :state="validationState(errors.errors, 'name')" v-model="supplier.name" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<b-form-group label="Email Address" :invalid-feedback="validationInvalidFeedback(errors.errors, 'email')">
							<b-form-input disabled type="email" :state="validationState(errors.errors, 'email')" v-model="supplier.email" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<b-form-group label="Contact Number" :invalid-feedback="validationInvalidFeedback(errors.errors, 'contact_number')">
							<b-form-input disabled :state="validationState(errors.errors, 'contact_number')" v-model="supplier.contact_number" />
						</b-form-group>
					</b-col>
				</b-row>
        <!-- Supplier Rep -->
        <b-row class="mb-4">
            <b-col cols="12">
            <b-card>
                <b-row>
                    <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('rep')" role="button">
                        <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['rep'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                        <div class="d-flex flex-column flex-fill ml-4">
                        <p class="h4 font-weight-bold mb-0">Supplier Rep</p>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['rep']">
                    <b-col cols="12">
                        <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'rep_name')">
                        <b-form-input disabled :state="validationState(errors.errors, 'rep_name')" v-model="supplier.rep_name" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Email" :invalid-feedback="validationInvalidFeedback(errors.errors, 'rep_email')">
                        <b-form-input disabled :state="validationState(errors.errors, 'rep_email')" v-model="supplier.rep_email" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Contact Number" :invalid-feedback="validationInvalidFeedback(errors.errors, 'rep_number')">
                        <b-form-input disabled :state="validationState(errors.errors, 'rep_number')" v-model="supplier.rep_number" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
            </b-col>
        </b-row>

          <!-- Supplier Address -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" @click.prevent="toggleCollapse('address')" role="button">
                    <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['address'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Supplier Address</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['address']">
                  <b-col cols="12">
                    <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors.errors, 'address_line_1')">
                      <b-form-input disabled :state="validationState(errors.errors, 'address_line_1')" v-model="supplier.address_line_1" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors.errors, 'address_line_2')">
                      <b-form-input disabled :state="validationState(errors.errors, 'address_line_2')" v-model="supplier.address_line_2" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors.errors, 'county')">
                      <b-form-input disabled :state="validationState(errors.errors, 'county')" v-model="supplier.county" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors.errors, 'postcode')">
                      <b-form-input disabled :state="validationState(errors.errors, 'postcode')" v-model="supplier.postcode" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import validation from "../../mixins/validation"
import { mapActions, mapGetters } from "vuex"

export default {
	mixins: [validation],
	created() {
		if (this.$route.params.id) {
			this.fetch(this.$route.params.id)
		}
	},
	computed: {
		...mapGetters("suppliers", ["errors", "isLoading", "isSaving", "single"]),
	},
	data() {
		return {
			collapsed: {
				address: !this.$route.params.id,
			},

			supplier: {
				name: null,
				contact_number: null,
				address_line_1: null,
				address_line_2: null,
				county: null,
				postcode: null,
				email: null,
				group: null,
				email_confirmation: null,
			},
		}
	},
	methods: {
		...mapActions("suppliers", ["fetch", "save"]),

		toggleCollapse(name) {
			this.collapsed[name] = !this.collapsed[name]
		},

		submit() {
			let data = {
				id: this.$route.params.id,
				name: this.supplier.name,
				email: this.supplier.email,
				email_confirmation: this.supplier.email_confirmation,
				contact_number: this.supplier.contact_number,
				address_line_1: this.supplier.address_line_1,
				address_line_2: this.supplier.address_line_2,
				county: this.supplier.county,
				postcode: this.supplier.postcode,
			}

			this.save(data).then((response) => {
				if ([200, 201].includes(response.status))
					this.$router.push({
						name: "dash.suppliers.list",
						params: {
							notifications: [
								{
									variant: "success",
									title: `Supplier #${response.data.id}`,
									subtitle: `${response.data.name}`,
									text: `You have ${!this.$route.params.id ? "created a new" : "updated an existing"} supplier.`,
									timeout: 5000,
								},
							],
						},
					})
			})
		},
	},
	watch: {
		single() {
			this.supplier = this.single
			this.supplier.email_confirmation = this.single.email
		},
	},
}
</script>

<style>
</style>
