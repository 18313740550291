<template>
	<router-view />
</template>

<script>
import supplierModule from "../../store/suppliers"

export default {
	beforeCreate() {
		this.$store.registerModule("suppliers", supplierModule)
	},
	destroyed() {
		this.$store.unregisterModule("suppliers")
	},
}
</script>
